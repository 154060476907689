import React from 'react';
import _ from 'lodash';

// services

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// utils
import { toggleModalDirect, openSnackBar } from 'utils/storeUtils';

import {
  bulkResendInvites,
  bulkDeleteActivations,
} from 'api/UserActivationsService';
import { publishEvent } from 'utils/eventUtils';
import { useTranslation } from 'react-i18next';

const ActivationsConfirmAction = (props) => {
  function handleCancel() {
    toggleModalDirect('ActivationsConfirmAction', false);
  }

  function handleSubmit() {
    const payload = Object.keys(_.get(props, 'data.checkedUsers', [])).map(
      (key) => key
    );
    if (props.data.action === 'resend') {
      bulkResendInvites({}, payload)
        .then(() => {
          toggleModalDirect('ActivationsConfirmAction', false);
          openSnackBar(
            t('MTD.ACTIVATIONS.ACTIVATION_INVITATIONS_WERE_RE_SENT')
          );
          // refetch table which will also reset checkmarks
          publishEvent('table:force-fetch-deviceActivations');
        })
        .catch((error) => console.error(error));
    } else {
      bulkDeleteActivations({}, payload)
        .then(() => {
          // refetch table which will also reset checkmarks
          publishEvent('table:force-fetch-deviceActivations');
          toggleModalDirect('ActivationsConfirmAction', false);
          openSnackBar(t('MTD.ACTIVATIONS.END_USER_ACTIVATION_S_WERE_DELETED'));
        })
        .catch((error) => console.error(error));
    }
  }

  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  if (_.isEmpty(props.data.checkedUsers)) {
    return (
      <DialogContent>
        <p>
          {t('MTD.ACTIVATIONS.PLEASE_SELECT_AT_LEAST_ONE_END_USER_ACTIVATION')}
        </p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={handleCancel}
            color="secondary"
            buttonText="Close"
          />
        </DialogActions>
      </DialogContent>
    );
  }
  return (
    <DialogContent>
      {props.data.action === 'resend' && (
        <p>{t('MTD.ACTIVATIONS.CONFIRM_RE_SEND_ACTIVATION_LINK')}</p>
      )}
      {props.data.action === 'delete' && (
        <p>{t('MTD.ACTIVATIONS.CONFIRM_DELETE_SELECTED_END_USER')}</p>
      )}

      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={handleCancel}
          color="secondary"
          buttonText={t('GLOBAL.CANCEL')}
        />
        <ZButton
          action={handleSubmit}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText={t('GLOBAL.SUBMIT')}
        />
      </DialogActions>
    </DialogContent>
  );
};

ActivationsConfirmAction.defaultProps = {
  data: {},
};

export default ActivationsConfirmAction;
