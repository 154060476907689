import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import Button from 'components/UI/Button';
import React from 'react';
import { getActiveModalAtom } from 'atoms/modals';
import { useSetRecoilState } from 'recoil';
import { ZScanUploadApp_TITLE } from 'components/modals/ZScanUploadApp';
import { IZScanApp } from './models';

interface IUpdateAppButtonProps {
  buttonText?: string;
}
const activeModalAtom = getActiveModalAtom<IZScanApp>();
const UploadAppButton: React.FC<IUpdateAppButtonProps> = ({ buttonText }) => {
  const setActiveModal = useSetRecoilState(activeModalAtom);

  return (
    <ProtectedComponent allow={{ zdev_builds: 'upload' }}>
      <Button
        color="secondary"
        onClick={() => setActiveModal({ active: ZScanUploadApp_TITLE })}
        text={buttonText ?? "Upload New App"}
      />
    </ProtectedComponent>
  );

};

export default UploadAppButton;