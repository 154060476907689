import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';

// material ui
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import ThreatIcon from '@material-ui/icons/Camera';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

const NavLinkWithRef = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const styles = ({ config, palette, sizing }) => ({
  drawerPaper: {
    width: 165,
  },

  mainSidebar: {
    width: sizing.sidebar.maxWidth,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100%',
    flexDirection: 'column',
    background: palette.background.default,
    position: 'fixed',
    height: '100vw',
  },

  navBottom: {
    position: 'fixed',
    bottom: 20,
    width: sizing.sidebar.maxWidth,
    zIndex: 200,
    flex: 1,
  },

  navTop: {
    flex: 1,
    zIndex: 200,
    width: sizing.sidebar.maxWidth,
  },

  listItemText: {
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: config.fonts.stackSans,
    lineHeight: '1.46429em',
  },

  linkWrapper: {
    textDecoration: 'none',
    padding: 0,
    transition: 'none',
  },

  active: {
    background: palette.background.paper,
    color: palette.primary.main,
    fontWeight: 'bold',

    '& svg': {
      color: palette.primary.main,
    },
  },

  activeIcon: {
    color: palette.primary.main,
  },

  nonActiveIcon: {
    color: palette.text.primary,
  },
});

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extraHeader: this.props.extraHeader,
      activeRoute: this.props.match.path,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.path !== this.props.match.path) {
      this.setState({
        activeRoute: nextProps.match.path,
      });
    }
  }

  generateClassName = (path) => {
    const { classes, location } = this.props;
    let active = false;

    if (path === 'dashboard') {
      active = location.pathname === '/dashboard';
    } else {
      active = location.pathname.indexOf(path) !== -1;
    }

    return classnames(classes.linkWrapper, {
      [classes.active]: active,
    });
  };

  render() {
    const { classes, match } = this.props;

    return (
      <div className={`${classes.mainSidebar} ${'main-sidebar'}`}>
        <div className={`${classes.navTop} ${'main-sidebar-navtopcontent'}`}>
          <ListItem
            button
            to={`${match.url}/accounts`}
            component={NavLinkWithRef}
            className={classes.linkWrapper}
            activeClassName={this.generateClassName('accounts')}
          >
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon className={classes.nonActiveIcon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                secondary="Accounts"
                className={classes.listItemText}
              />
            </ListItem>
          </ListItem>

          <ProtectedComponent allow={{ superusers: 'manage' }} isSuper>
            <ListItem
              button
              component={NavLinkWithRef}
              to={`${match.url}/superusers`}
              className={classes.linkWrapper}
              activeClassName={classes.active}
            >
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon className={classes.nonActiveIcon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  secondary="Super Users"
                  className={classes.listItemText}
                />
              </ListItem>
            </ListItem>
          </ProtectedComponent>
          <ProtectedComponent allow={{ superusers: 'manage' }} isSuper>
            <ListItem
              button
              component={NavLinkWithRef}
              to={`${match.url}/partnerusers`}
              className={classes.linkWrapper}
              activeClassName={classes.active}
            >
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon className={classes.nonActiveIcon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  secondary="Partner Users"
                  className={classes.listItemText}
                />
              </ListItem>
            </ListItem>
          </ProtectedComponent>
          <ProtectedComponent allow={{ superusers: 'manage' }} isSuper>
            <ListItem
              button
              component={NavLinkWithRef}
              to={`${match.url}/threatdefaults`}
              className={classes.linkWrapper}
              activeClassName={classes.active}
            >
              <ListItem button>
                <ListItemIcon>
                  <ThreatIcon className={classes.nonActiveIcon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  secondary="Threat Defaults"
                  className={classes.listItemText}
                />
              </ListItem>
            </ListItem>
          </ProtectedComponent>
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SideBar));
