import Login from 'components/entry/Login';
import Logout from 'components/entry/Logout';
import ForgotPassword from 'components/entry/ForgotPassword';
import TermsOfService from 'components/main/common/TermsOfService';
import ConfirmPasswordReset from 'components/entry/ConfirmPasswordReset';
import SSOLogin from 'components/entry/SSOLogin';
/* admin */
import Account from 'components/admin/accounts/Accounts';
import SuperUsers from 'components/admin/superusers/SuperUsers';
// import Plans from 'components/admin/plans/Plans';
import TRMPolicy from 'components/admin/threatDefaults/TRMPolicy';
import TwoFactorLanding from 'components/entry/twoFactorAuth/TwoFactorLanding';
import PartnerUsers from 'components/admin/partnerUsers/PartnerUsers';
/* partner */
import ManagedAccounts from 'components/partner/managedAccounts';
import PartnerUsersForPartnerUI from 'components/partner/PartnerUsersForPartnerUI';
import PartnerAuthorizations from 'components/partner/PartnerAuthorizations';
import PartnerBranding from 'components/partner/branding';
import UserInvitationConfirmation from 'components/entry/UserInvitationConfirmation';
import GlobalSettings from 'components/admin/globalCACSettings';

export const PUBLIC_ROUTE_MAPPING = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/logout',
    component: Logout,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/confirmPasswordReset/:token',
    component: ConfirmPasswordReset,
    exact: true,
  },
  {
    path: '/terms',
    component: TermsOfService,
    exact: true,
  },
  {
    path: '/sso',
    component: SSOLogin,
    exact: true,
  },
  {
    path: '/tfa',
    component: TwoFactorLanding,
    exact: true,
  },
  {
    path: '/confirmUser/:token',
    component: UserInvitationConfirmation,
    exact: true,
  },
];

export const ADMIN_ROUTE_MAPPING = [
  {
    path: '/admin/accounts',
    component: Account,
    exact: true,
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
  {
    path: '/admin/superusers',
    component: SuperUsers,
    requiredScope: 'superusers',
    exact: true,
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
  {
    path: '/admin/partnerusers',
    component: PartnerUsers,
    requiredScope: 'superusers',
    exact: true,
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
  {
    path: '/admin/threatdefaults',
    component: TRMPolicy,
    requiredScope: 'superusers',
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
];

export const PARTNER_ROUTE_MAPPING = [
  {
    path: '/partner/accounts',
    component: ManagedAccounts,
    requiredScope: { managed_accounts: 'enter' },
    exact: true,
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
  {
    path: '/partner/users',
    component: PartnerUsersForPartnerUI,
    requiredScope: { partner_users: 'manage' },
    exact: true,
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
  {
    path: '/partner/authorizations',
    component: PartnerAuthorizations,
    requiredScope: { partner_api_keys: 'manage' },
    exact: true,
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
  {
    path: '/partner/branding',
    component: PartnerBranding,
    requiredScope: { branding: 'manage' },
    exact: true,
  },
];
