import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { IProtectionCoverage } from './models';
import { IzShieldOSType } from '../ZShieldApps/models';

export const PROTECTION_COVERAGE_FIELDS: IProtectionCoverage[] = [
  {
    id: 1,
    displayName: 'Static DEX encryption',
    fieldName: 'staticDexEncryption',
    tooltip:
      'Encrypts most of the .dex files of your app to provide protection against static analysis. It means that these files will be stored in encrypted form and decrypted only at runtime when they are used. Note that some common Android and Kotlin packages will not be encrypted.',
    osType: IzShieldOSType.ANDROID,
  },
  {
    id: 2,
    fieldName: 'resourceEncryption',
    displayName: 'Resource encryption',
    tooltip:
      'Encrypts most resources in your app’s assets directory, such as text, XML, image, video, and music files. These protected resources will be stored in encrypted form and decrypted only at runtime when they are actually used.',
    osType: IzShieldOSType.ANDROID,
  },
  {
    id: 3,
    displayName: 'Signature verification',
    fieldName: 'signatureVerification',
    tooltip:
      'When the application runs this feature verifies that the application was signed with the cert/key you provide. Please see the documentation for more information.',
    osType: IzShieldOSType.ANDROID,
  },

  {
    id: 4,
    displayName: 'Runtime Protection',
    fieldName: 'runtimeProtection',
    tooltip: 'Automatically inject Zimperium’s zDefend Runtime Protection',
    osType: IzShieldOSType.ANDROID,
  },
];

export const PROTECTION_COVERAGE_FIELDS_IOS: IProtectionCoverage[] = [
  {
    id: 1,
    displayName: 'Metadata encryption',
    fieldName: 'metadataEncryption',
    tooltip:
      'Encrypts app metadata to hide sensitive information from reverse engineering. The encrypted data will be decrypted only once the protected app is loaded.',
    osType: IzShieldOSType.IOS,
  },
  {
    id: 2,
    fieldName: 'signatureVerification',
    displayName: 'Signature encryption',
    tooltip: 'Prevents app cloning and re-signing attacks.',
    osType: IzShieldOSType.IOS,
  },
  {
    id: 3,
    displayName: 'Code Obfuscation',
    fieldName: 'codeObfuscation',
    tooltip:
      'Transforms code so that it becomes more difficult to analyze by various hacker tools.',
    osType: IzShieldOSType.IOS,
  },
  {
    id: 4,
    displayName: 'Runtime Protection',
    fieldName: 'runtimeProtection',
    tooltip: 'Automatically inject Zimperium’s zDefend Runtime Protection',
    osType: IzShieldOSType.IOS,
  },
];

export const ProtectionLabel = ({
  displayName,
  tooltip,
  isLink,
  placement,
}: {
  displayName: string;
  tooltip: string;
  isLink?: boolean;
  placement?: any;
}) => {
  return (
    <div className="flex--start">
      <div>{displayName}</div>
      <Tooltip
        style={{ zIndex: '1301' }}
        arrow
        enterDelay={300}
        placement={placement ? placement : 'left'}
        title={<span style={{ fontSize: 12 }}>{tooltip}</span>}
      >
        {!isLink ? (
          <InfoOutlined color="primary" style={{ marginLeft: 3, padding: 3 }} />
        ) : (
          <a href="#" target="_blank" className="flex--start">
            <InfoOutlined
              color="primary"
              style={{ marginLeft: 3, padding: 3 }}
            />
          </a>
        )}
      </Tooltip>
    </div>
  );
};
