import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  getAvailableTeamsAsSelectList,
  getAvailableTeams,
} from 'reducers/TeamReducers';

// components
import { MultiSelect } from 'components/Selects';
import { TextField } from 'components/inputs';

// helpers
import { reduceForMultiSelect } from 'utils/componentUtils';

// mappings
import severitiesMapping from 'utility/Severities';

// material ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import Typography from '@material-ui/core/Typography';
import ZButton from 'UI/Buttons/ZButton';
import RequiredSelectWrapper from '../Selects/MultiSelect/RequiredSelectWrapper';
// services
import {
  createDataExportConfig,
  createDataExportConfigCert,
  updateDataExportConfig,
  updateDataExportConfigCert,
  fetchDataTypes,
  fetchDestinationTypes,
  fetchActiveDestinationTypes,
  fetchSeverityCriteria,
  getCertificateDetails,
} from 'api/DataExportService';
import Grid from '@material-ui/core/Grid';
// import { Api } from 'config/axiosConfig';
import { applyMapping } from 'utils/mapUtils';
import { createEditDataExportPayloadMapping } from 'mappings/services/dataExportServiceMapping';
import GenericErrorBox from 'components/inputs/GenericErrorBox';
import {
  toggleModalDiffered,
  toggleModalDirect,
  openSnackBar,
} from '../../utils/storeUtils';
import { publishEvent } from '../../utils/eventUtils';
import CheckBox from '../inputs/Checkbox';

import { Configuration } from '../dataexport';
import {
  dataTypes,
  destinationTypes,
} from '../dataexport/enums/DataExportEnums';

const initialState = {
  name: '',
  concise: true,
  isRunning: true,
  description: '',
  dataType: [],
  destinationType: [],

  // connection details
  config: {},
  // criteria
  severityCriteria: [],
  teamCriteria: [],
  externalTrackingId1: '',
  externalTrackingId2: '',
  active: true,
  forensicsEnabled: false,
  trustedCAFileName: '',
  markTrustedCAToBeDeleted: false,
};

const MultiSelectRequired = (props) => (
  <RequiredSelectWrapper {...props} SelectComponent={MultiSelect} />
);
class DataExportFormModal extends PureComponent {
  editMode = false;

  constructor(props) {
    super(props);

    let data = {};
    if (!_.isEmpty(props.data.rowData)) {
      this.editMode = true;
      data = this.parseDataExportConfig(props.data.rowData);
    }

    this.state = this.editMode
      ? {
          ...initialState,
          ...data,
        }
      : {
          ...initialState,
          availableDataTypes: [],
          availableDestinationTypes: [],
          allDestinationTypes: [],
          availableSeverityCriteria: [],
          availableTeams: [],
        };

    this.holdState = {};
    this.onDestinationChangedObject = {
      changed: false,
      fnc: this.falsifyChange,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.parseConditions = this.parseConditions.bind(this);
    this.getCertificateDetails = this.getCertificateDetails.bind(this);
    this.isConsoleAuditLogExportSupported =
      this.isConsoleAuditLogExportSupported.bind(this);
    this.shouldDisableShowCertificateButton =
      this.shouldDisableShowCertificateButton.bind(this);
  }

  componentDidMount() {
    Promise.all([
      fetchDataTypes(),
      fetchDestinationTypes(),
      fetchSeverityCriteria(),
      fetchActiveDestinationTypes(),
    ]).then((values) => {
      this.setState({
        availableDataTypes: values[0],
        availableDestinationTypes: values[1],
        availableSeverityCriteria: reduceForMultiSelect(values[2]),
        availableTeams: this.props.availableTeams,
        activeDestinationTypes: _.get(values[3], 'data', []),
      });
      const mapped = _.map(this.state.activeDestinationTypes, (it) => it.name);
      const filteredDestinationTypes = _.filter(
        this.state.availableDestinationTypes,
        (it) => _.includes(mapped, it.value)
      );

      this.setState({
        availableDestinationTypes: filteredDestinationTypes,
        allDestinationTypes: filteredDestinationTypes,
      });
      if (this.editMode) {
        if (!this.isConsoleAuditLogExportSupported()) {
          this.setState({
            availableDataTypes: _.filter(
              this.state.availableDataTypes,
              (dt) => dt.value === 'THREATS'
            ),
          });
        }
      }
    });

    if (_.hasIn(this.props.data, 'tenantID')) {
      this.setState({
        ..._.get(this.props.data, 'formState'),
      });
      // disable opening the popup on next refresh
      _.invoke(this.props, 'saveToMicrosoftInStore', {
        microsoft_login_pending: false,
        formState: this.props.data.formState,
      });
    }
    this.determineForensicsAvailability(_.get(this.state, 'destinationType'));
  }

  falsifyChange = () => {
    this.onDestinationChangedObject.changed = false;
  };

  onThreatReporterConfigChange = (threatReporterConfig) => {
    const newState = {
      config: { ...threatReporterConfig },
      trustedCAFileName: threatReporterConfig.trustedCAFileName,
      markTrustedCAToBeDeleted:
        threatReporterConfig.markTrustedCAToBeDeleted &&
        threatReporterConfig.markTrustedCAToBeDeleted,
      concise: (threatReporterConfig.authorizationType?.value === 'API_KEY' &&
                threatReporterConfig.apiKeyFormat?.value === 'CROWDSTRIKE') || this.state.concise,
    };

    this.setState({ ...newState });
  };

  // setTrustedCAToBeDeleted = value =>
  //   this.setState({ markTrustedCAToBeDeleted: value });

  formatFingerprints = (Fingerprints) => {
    if (!Fingerprints) return;
    return `SubjectDN: ${Fingerprints.subjectDN}\nSHA-256: ${Fingerprints.sha256}\nSHA-1: ${Fingerprints.sha1}\nMD5: ${Fingerprints.md5}`;
  };

  render() {
    const { state } = this;
    this.holdState = _.omit(state, [
      'config',
      'availableDataTypes',
      'availableDestinationTypes',
      'availableSeverityCriteria',
    ]);
    return (
      <DialogContent>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={8}>
            <Grid item md={6}>
              <TextField
                required
                label="Name"
                value={state.name}
                onChange={this.handleChange('name')}
              />
              <TextField
                required
                label="Description"
                value={state.description}
                onChange={this.handleChange('description')}
              />
              <MultiSelectRequired
                name="dataType"
                label="Data Type"
                isMulti={false}
                buttonPlaceholder="Select a Data Type"
                options={state.availableDataTypes}
                onChange={this.handleSelect}
                values={state.dataType}
              />
              {_.get(_.head(state.dataType), 'value') === 'THREATS' && (
                <div>
                  <MultiSelectRequired
                    label="Severity Criteria"
                    name="severityCriteria"
                    buttonPlaceholder="Select a Severity Criteria"
                    options={state.availableSeverityCriteria}
                    onChange={this.handleSelect}
                    values={state.severityCriteria}
                  />
                  <MultiSelect
                    label="Team Criteria"
                    name="teamCriteria"
                    buttonPlaceholder="Select a Team Criteria"
                    options={state.availableTeams}
                    onChange={this.handleSelect}
                    values={state.teamCriteria}
                  />
                  <TextField
                    label="External Tracking ID1"
                    value={state.externalTrackingId1}
                    onChange={this.handleChange('externalTrackingId1')}
                  />
                  <TextField
                    label="External Tracking ID2"
                    value={state.externalTrackingId2}
                    onChange={this.handleChange('externalTrackingId2')}
                  />
                  <CheckBox
                    disabled={(state.config?.authorizationType?.value === 'API_KEY' &&
                               state.config?.apiKeyFormat?.value === 'CROWDSTRIKE') || !state.forensicsEnabled}
                    label="Include Threat Forensics"
                    checked={!state.concise}
                    onChange={this.handleCheckChange('concise')}
                  />
                </div>
              )}
            </Grid>
            <Grid item md={6}>
              <MultiSelectRequired
                label="Destination Type"
                name="destinationType"
                isMulti={false}
                buttonPlaceholder="Select a Destination Type"
                options={state.availableDestinationTypes}
                onChange={this.handleSelect}
                values={state.destinationType}
              />
              <Configuration
                onDestinationChangedObject={this.onDestinationChangedObject}
                formState={this.holdState}
                reduxWrite={this.props.saveToMicrosoftInStore}
                queryParams={this.getQueryParams()}
                editModeData={this.editMode ? state.config : null}
                onConfigChange={this.onThreatReporterConfigChange}
                accountId={this.props.accountId}
              />
            </Grid>
          </Grid>
          <TextField
            disabled
            fullWidth
            multiline
            rows="12"
            isShowing={!state.error && !_.isEmpty(this.state.certFingerprints)}
            label="Server Certificate Details"
            value={this.formatFingerprints(this.state.certFingerprints)}
          />

          <div style={{ paddingTop: 20 }}>
            <GenericErrorBox errorMessage={state.error} />
          </div>
          <DialogActions>
            <ZButton
              styleName="modalCancel"
              action={toggleModalDiffered('DataExportCreateEdit', false)}
              color="secondary"
              buttonText="Cancel"
            />
            <ZButton
              buttonType="submit"
              color="primary"
              styleName="modalSave"
              buttonText="Save Configuration"
            />
            <ZButton
              color="primary"
              buttonText="View Certificate"
              isShowing={this.shouldShowCertificateButton()}
              isDisabled={this.shouldDisableShowCertificateButton()}
              onClick={this.getCertificateDetails}
            />
          </DialogActions>
        </form>
      </DialogContent>
    );
  }

  shouldDisableShowCertificateButton() {
    const endpoint = _.get(this.state.config, 'endpoint');
    const port = _.get(this.state.config, 'port');
    if (_.isEmpty(endpoint)) {
      return true;
    }
    // eslint-disable-next-line
    if (Number(port) === NaN) {
      return true;
    }
    return false;
  }

  getCertificateDetails() {
    getCertificateDetails(
      _.get(this.state.config, 'endpoint'),
      _.get(this.state.config, 'port')
    )
      .then((resp) => {
        const certFingerprints = resp.data;
        for (const prop in certFingerprints) {
          if (prop === 'subjectDN') {
            continue;
          }
          certFingerprints[prop] = certFingerprints[prop].replace(
            /(..)(?!$)/g,
            '$1:'
          );
        }
        this.setState({ certFingerprints, error: null });
      })
      .catch((error) => {
        this.setState({
          error: `An error occurred: ${_.get(
            error,
            'response.data.message',
            'unknown'
          )}`,
        });
      });
  }

  shouldShowCertificateButton() {
    return (
      this.isConsoleAuditLogExportSupported() &&
      _.get(this.state.config, 'transportType.value') === 'TCP_TLS'
    );
  }

  isConsoleAuditLogExportSupported() {
    return ['KINESIS', 'SIEM'].includes(
      _.get(_.head(this.state.destinationType), 'value')
    );
  }

  getQueryParams() {
    return this.props.data;
  }

  handleSelect(name, selectedOption) {
    // this is needed for populating multiSelect
    const option = _.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    if (name === 'destinationType') {
      this.onDestinationChangedObject.changed = true;
      this.setState({ [name]: option, config: {} });
      this.determineForensicsAvailability(option);
      return;
    }
    if (name === 'dataType') {
      this.setState({ destinationType: null });
      if (_.head(option).value === 'AUDIT') {
        this.setState({
          availableDestinationTypes: _.filter(
            this.state.allDestinationTypes,
            (dt) => ['KINESIS', 'SIEM'].includes(dt.value)
          ),
        });
      } else {
        this.setState({
          availableDestinationTypes: this.state.allDestinationTypes,
        });
      }
    }
    this.setState({ [name]: option });
  }

  determineForensicsAvailability(option) {
    const destinationType = _.get(_.head(option), 'value', null);

    if (destinationType == null) return;

    this.setState({
      forensicsEnabled: [
        'KINESIS',
        'ATT_ALIEN_VAULT',
        'REST_ENDPOINT',
        'SIEM',
        'MICROSOFT_SENTINEL',
      ].includes(destinationType),
    });

    if (this.editMode) return;

    this.setState({ concise: destinationType !== 'KINESIS' });
  }

  handleChange(field) {
    return (event) => {
      this.setState({
        [field]: event.target.value,
      });
    };
  }

  handleCheckChange(field) {
    return () => {
      this.setState({
        [field]: !this.state.concise,
      });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const { props, state } = this;

    let updateData = updateDataExportConfig;
    let createData = createDataExportConfig;

    let fd;
    const jsonPayload = {
      ...state,
      config: _.omit(state.config, [
        'transportTypeTrustedCAFile',
        'trustedCAFileName',
        'markTrustedCAToBeDeleted',
      ]),
    };

    if (
      _.get(state.config, 'transportType.value') === 'TCP_TLS' &&
      state.config.transportTypeTrustedCAFile instanceof File
    ) {
      updateData = updateDataExportConfigCert;
      createData = createDataExportConfigCert;
      fd = new FormData();
      fd.append(
        'configuration',
        JSON.stringify(
          _.omit(applyMapping(createEditDataExportPayloadMapping, jsonPayload))
        )
      );
      fd.append('trustedCA', state.config.transportTypeTrustedCAFile);
    }

    const newState = _.omit(state, [
      'siemConfiguration.transportTypeTrustedCAFile',
      'siemConfiguration.trustedCAFileName',
      'siemConfiguration.markTrustedCAToBeDeleted',
    ]);

    if (this.editMode) {
      // Edit Data Export Configuration
      const shouldDelete = newState.markTrustedCAToBeDeleted || false;
      // use this param in query params ?deletedTrustedCA={shouldDelete}
      updateData(
        {
          dataExportConfigId: props.data.rowData.id,
          deletedTrustedCA: shouldDelete,
        },
        fd || jsonPayload
      )
        .then(() => {
          toggleModalDirect('DataExportCreateEdit', false);
          openSnackBar('Data export configuration was updated');
          publishEvent('table:force-fetch-dataExport');
        })
        .catch((error) => {
          this.setState({
            error: `An error occurred: ${_.get(
              error,
              'response.data.message',
              'unknown'
            )}`,
          });
        });
    } else {
      // Create Data Export Configuration
      if (!_.get(_.head(newState.dataType), 'value') === 'AUDIT') {
        if (_.isEmpty(newState.severityCriteria)) {
          const error = 'Severity criteria must be selected';
          this.setState({ error: `An error occurred: ${error}` });
          return;
        }
      }
      createData({}, fd || jsonPayload)
        .then(() => {
          toggleModalDirect('DataExportCreateEdit', false);
          openSnackBar('Data export configuration was created');
          publishEvent('table:force-fetch-dataExport');
        })
        .catch((error) => {
          if (error.response.status === 409) {
            this.setState({
              error: 'Data Export with this name already exists.',
            });
          } else {
            this.setState({
              error: `An error occurred: ${_.get(
                error,
                'response.data.message',
                'unknown'
              )}`,
            });
          }
        });
    }
  }

  parseDataExportConfig(rowData) {
    const dataType = reduceForMultiSelect(
      mapValueForSelect(_.get(rowData, 'dataType'))
    );
    const destinationType = [
      mapValueForDestinationTypeSelect(_.get(rowData, 'destinationType')),
    ];
    const conditions = this.parseConditions(_.get(rowData, 'conditions', []));

    const getConfigKey = (key) => {
      return _.head(
        _.filter(
          destinationTypes,
          (destinationType) => destinationType.value === key
        )
      ).configKey;
    };

    return {
      ..._.pick(rowData, [
        'name',
        'description',
        'concise',
        'isRunning',
        'trustedCAFileName',
      ]),
      dataType,
      destinationType,
      config: _.get(
        rowData,
        getConfigKey(_.get(_.head(destinationType), 'value'))
      ),
      ...conditions,
    };
  }

  parseConditions(rawConditions) {
    if (rawConditions === null) {
      return null;
    }
    const { props } = this;

    const severities = rawConditions.filter(
      ({ field }) => field === 'severity'
    )[0];

    const teamIds = rawConditions.filter(({ field }) => field === 'teamId')[0];

    const rest = rawConditions.filter(
      ({ field }) => field !== 'severity' && field !== 'teamId'
    );

    return {
      severityCriteria: _.get(severities, 'value', []).map((value) => ({
        label: severitiesMapping[value].name,
        value,
      })),
      teamCriteria: _.get(teamIds, 'value', []).map((value) => ({
        label: _.get(props.availableTeamsHash[value], 'name'),
        value,
      })),
      ...rest.reduce(
        (acc, { field, value }) => ({
          ...acc,
          [_.last(field.split('.'))]: value,
        }),
        {}
      ),
    };
  }
}

function mapValueForSelect(value) {
  if (value === 'TCP_TLS_CERT') {
    // safegaurd; revert to using TCP_TLS
    value = 'TCP_TLS';
  }
  return [
    {
      id: value,
      name: dataTypes[_.findIndex(dataTypes, (_) => _.value === value)].label,
    },
  ];
}

function mapValueForDestinationTypeSelect(value) {
  return destinationTypes[
    _.findIndex(destinationTypes, (_) => _.value === value)
  ];
}

const mapStateToProps = (state) => {
  return {
    availableTeamsHash: getAvailableTeams(state),
    availableTeams: getAvailableTeamsAsSelectList(state),
    accountId: _.get(state, 'auth.user.accountId'),
  };
};

DataExportFormModal.defaultProps = {
  data: {},
};

const saveUserMicrosoftLoginPendingToRedux = (dispatch) => ({
  saveToMicrosoftInStore: (savedConfig) => {
    dispatch({
      type: 'MICROSOFT_HANDLER',
      object: savedConfig,
    });
  },
});

export default connect(
  mapStateToProps,
  saveUserMicrosoftLoginPendingToRedux
)(DataExportFormModal);
