import React, { Fragment } from 'react';

import Moment from 'moment';
import CropFree from '@material-ui/icons/CropFree';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import { toggleModalDiffered } from 'utils/storeUtils';
import momentHelper from 'utils/momentUtils';
import ProtectedComponent from '../protected/ProtectedComponent';

export const GroupActivationsTableHeaders = [
  { id: 'name', label: 'GLOBAL.NAME', show: true, sort: true },
  { id: 'description', label: 'GLOBAL.DESCRIPTION', show: true, sort: true },
  {
    id: 'activationCount',
    label: 'MTD.ACTIVATIONS.ACTIVATION_COUNT',
    show: true,
    sort: true,
  },
  {
    id: 'activationLimit',
    label: 'MTD.ACTIVATIONS.ACTIVATION_LIMIT',
    show: true,
    sort: true,
  },
  { id: 'team.name', label: 'GLOBAL.TEAM', show: true, sort: false },
  { id: 'groupName', label: 'GLOBAL.POLICY_GROUP', show: true, sort: false },
  { id: 'linkExpiry', label: 'GLOBAL.EXPIRY', show: true, sort: true },
  { id: 'created', label: 'GLOBAL.CREATED', show: true, sort: true },
  { id: 'modified', label: 'GLOBAL.MODIFIED', show: false, sort: true },
  { id: 'actions', label: 'GLOBAL.ACTIONS', show: true },
];

export const fetchGroupActivationsParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
  {
    path: 'groupId',
  },
  {
    path: 'teamId',
    paramKey: 'teamIds',
  },
];

export const GroupActivationsColumnMapping = (t) => [
  { path: 'name' },
  { path: 'description' },
  { path: 'activationCount' },
  {
    path: 'activationLimit',
    columnContent: (limit) => {
      if (limit == null) {
        return 'N/A';
      }
      return limit;
    },
  },
  {
    path: 'team.name',
  },
  {
    path: 'groupName',
    columnContent: (groupName) => {
      return (
        <Tooltip title={groupName}>
          <span
            style={{
              width: 150,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
            }}
          >
            {groupName}
          </span>
        </Tooltip>
      );
    },
  },
  {
    path: 'linkExpiry',
    columnContent: (linkExpiry) => {
      if (linkExpiry == null) {
        return '';
      }
      if (Moment(linkExpiry).isBefore()) {
        return `Expired - ${Moment(linkExpiry).fromNow()}`;
      }
      return Moment(linkExpiry).fromNow();
    },
  },
  {
    path: 'created',
    columnContent: (created) => {
      return momentHelper(created);
    },
  },
  {
    path: 'modified',
    columnContent: (modified) => {
      return momentHelper(modified);
    },
  },
  {
    path: 'actions',
    columnContent: (
      id,
      { rowData, handlers: { availableTeams, selectedTeamId } }
    ) => {
      return (
        <Fragment>
          <Tooltip title={t('MTD.ACTIVATIONS.EDIT_ACTIVATION_LINK')}>
            <CropFree
              className="hover--link"
              onClick={toggleModalDiffered(
                'GroupActivationsLink',
                {
                  rowData,
                },
                {
                  title: t('MTD.ACTIVATIONS.MANAGE_ACTIVATION_LINK'),
                }
              )}
            />
          </Tooltip>
          <ProtectedComponent allow={{ zapps: 'manage' }}>
            <Tooltip title={t('MTD.ACTIVATIONS.EDIT_GROUP_ACTIVATION')}>
              <EditIcon
                className="hover--link"
                onClick={toggleModalDiffered(
                  'GroupActivationsCreateEdit',
                  {
                    availableTeams,
                    rowData,
                    selectedTeamId,
                  },
                  {
                    title: t('MTD.ACTIVATIONS.EDIT_GROUP_ACTIVATION'),
                    fullWidth: true,
                  }
                )}
              />
            </Tooltip>
          </ProtectedComponent>
          <ProtectedComponent allow={{ zapps: 'manage' }}>
            <Tooltip title={t('MTD.ACTIVATIONS.DELETE_GROUP_ACTIVATION')}>
              <DeleteIcon
                className="hover--link"
                onClick={toggleModalDiffered(
                  'GroupActivationsDeleteConfirm',
                  {
                    rowData,
                  },
                  {
                    title: t('MTD.ACTIVATIONS.DELETE_GROUP_ACTIVATION'),
                  }
                )}
              />
            </Tooltip>
          </ProtectedComponent>
        </Fragment>
      );
    },
  },
  { path: '' },
];
