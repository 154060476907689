import DialogContent from '@material-ui/core/DialogContent';
import useAccount from 'components/admin/accounts/useAccount';
import usePartnerAccounts from 'components/admin/accounts/usePartnerAccounts';
import usePlans from 'components/admin/accounts/usePlans';
import Button from 'components/UI/Button';
import GenericCard from 'components/UI/GenericCard';
import Checkbox from 'components/UI/input/Checkbox';
import GenericGroup from 'components/UI/input/GenericGroup';
import InputError from 'components/UI/input/InputError';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import Select from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import Tabs from 'components/UI/Tabs';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { toggleModalDirect } from 'utils/storeUtils';
import initialValues from './initialValues';
import { IAccountData, IAccountPlan, ThreatDefaultsSource } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import {
  DATA_RETENTION_VALUES,
  EXCLUDE_PLANS,
  generateFeatureListValues,
  GEOGRAPHY_OPTIONS,
  isLegacyAccount,
  MARKET_VERTICAL_OPTIONS,
} from './utils';
import { PlanFeaturesType } from './FeaturesTypes';
import { fetchFeaturesByPlanIds } from 'api/PlansService';
import GenericError from 'components/UI/GenericErrorBox';

interface IFormProps {
  data: Partial<IAccountData>;
  handleSubmit: any; //() => void;
  externalError: string | undefined;
}

const Form: React.FC<IFormProps> = ({ data, externalError, handleSubmit }) => {
  const classes = useStyles();
  // Providing type and structure to this hook until such time
  // that it provides it's own in the response.
  const managedAccounts: IAccountPlan[] = usePartnerAccounts(
    data?.id,
    !!data?.partnerModal
  )?.managedAccounts;
  const accountId = data?.id;

  // Providing type and structure to this hook until such time
  // that it provides it's own in the response.
  const plansList: IAccountPlan[] = usePlans()?.plansCheckboxList.map(
    ({ name, value, disabled }) => ({ id: value, name, disabled })
  );

  const { accountInfo: editAccountInfo } = useAccount(
    data?.selectedAccountId,
    !!data?.partnerModal
  );

  const resolvedInitialValues = initialValues({
    ...editAccountInfo,
    featureList: data?.featureList,
  } as Partial<IAccountData>);

  const isLoading =
    !plansList?.length ||
    (!data?.partnerModal && !(managedAccounts instanceof Array)) ||
    (!!data?.selectedAccountId && !resolvedInitialValues?.accountName);

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema(!!data?.partnerModal)}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        errors,
        handleSubmit: handleFormikSubmit,
      }) => (
        <div className={classes.form}>
          <FormikForm>
            <DialogContent>
              <Tabs
                hideTabs={!!data?.partnerModal}
                tabs={[
                  {
                    title: 'General',
                    content: (
                      <>
                        <FormikField
                          component={TextField}
                          label="Account Name"
                          name="accountName"
                          disabled={isLoading || isSubmitting}
                        />
                        <FormikField
                          component={TextField}
                          label="Description"
                          name="description"
                          disabled={isLoading || isSubmitting}
                        />
                        {!data?.partnerModal && (
                          <FormikField
                            component={TextField}
                            label="External Customer ID (optional)"
                            name="salesforceId"
                            disabled={isLoading || isSubmitting}
                          />
                        )}
                        {!data?.partnerModal && (
                          <RadioGroup label="Account Type">
                            <FormikField
                              color="primary"
                              component={Radio}
                              label="Default"
                              name="accountType"
                              size="small"
                              type="radio"
                              value="default"
                              disabled={isLoading || isSubmitting}
                            />
                            <FormikField
                              color="primary"
                              component={Radio}
                              label="Partner"
                              name="accountType"
                              size="small"
                              type="radio"
                              value="partner"
                              disabled={isLoading || isSubmitting}
                            />
                            <FormikField
                              color="primary"
                              component={Radio}
                              label="Managed"
                              name="accountType"
                              size="small"
                              type="radio"
                              value="managed"
                              disabled={isLoading || isSubmitting}
                            />
                          </RadioGroup>
                        )}
                        {!data?.partnerModal &&
                          values?.accountType === 'managed' &&
                          !isLoading && (
                            <FormikField
                              component={Select}
                              disabled={isSubmitting}
                              label="Partner Accounts"
                              multiple={false}
                              name="managingAccount"
                              options={managedAccounts}
                              setFieldValue={setFieldValue}
                              type="select"
                            />
                          )}

                        {!data?.partnerModal && (
                          <RadioGroup label="Threat Defaults Source">
                            <FormikField
                              color="primary"
                              component={Radio}
                              label="GTS"
                              name="threatDefaultsSource"
                              size="small"
                              type="radio"
                              value={ThreatDefaultsSource.GTS}
                              disabled={isLoading || isSubmitting}
                            />
                            <FormikField
                              color="primary"
                              component={Radio}
                              label="System Default"
                              name="threatDefaultsSource"
                              size="small"
                              type="radio"
                              value={ThreatDefaultsSource.SYSTEM_DEFAULT}
                              disabled={isLoading || isSubmitting}
                            />
                            <FormikField
                              color="primary"
                              component={Radio}
                              label="Account Default"
                              name="threatDefaultsSource"
                              size="small"
                              type="radio"
                              value={ThreatDefaultsSource.ACCOUNT_DEFAULT}
                              disabled={isLoading || isSubmitting}
                            />
                            {values?.accountType === 'managed' ? (
                              <FormikField
                                color="primary"
                                component={Radio}
                                label="Inherit Partner"
                                name="threatDefaultsSource"
                                size="small"
                                type="radio"
                                value={
                                  ThreatDefaultsSource.INHERIT_FROM_PARTNER
                                }
                                disabled={isLoading || isSubmitting}
                              />
                            ) : (
                              <></>
                            )}
                          </RadioGroup>
                        )}

                        <GenericCard
                          isLoading={isLoading}
                          loadingHeight={347}
                          title="account settings"
                        >
                          <GenericGroup label="Subscribed Plans">
                            {isLegacyAccount(data) && (
                              <div className={classes.legacyNotice}>
                                Consumer is subscribed to a non-upgradeable
                                legacy plan.
                              </div>
                            )}
                            <div className={classes.twoColumnGroup}>
                              {plansList
                                .filter(
                                  ({ name }) =>
                                    (isLegacyAccount(data) &&
                                      data?.planName === name) ||
                                    (!isLegacyAccount(data) &&
                                      !EXCLUDE_PLANS.includes(name))
                                )
                                .map(({ id, name, disabled }, idx) => (
                                  <FormikField
                                    component={Checkbox}
                                    disabled={disabled || isSubmitting}
                                    key={`plan-${idx}`}
                                    label={name}
                                    name="plans"
                                    type="checkbox"
                                    value={id}
                                    onClick={async (
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const checkedStatus =
                                        event?.target?.checked ?? false;
                                      const planId = event?.target?.value;
                                      if (!planId) {
                                        return;
                                      }
                                      const newPlanIds = checkedStatus
                                        ? [...values.plans, planId]
                                        : [...values.plans].filter(
                                            (item) => item !== planId
                                          );
                                      if (!newPlanIds?.length) {
                                        return setFieldValue('featureList', []);
                                      }
                                      const { data } =
                                        await fetchFeaturesByPlanIds(
                                          { planIds: newPlanIds },
                                          accountId
                                        );
                                      const newFeatureListSelection =
                                        generateFeatureListValues(data);
                                      setFieldValue(
                                        'featureList',
                                        newFeatureListSelection
                                      );
                                    }}
                                  />
                                ))}
                            </div>
                            {!!errors?.plans && (
                              <InputError override="Must select at least one plan." />
                            )}
                            <FormikField
                              component={TextField}
                              disabled={isLoading || isSubmitting}
                              label="Subscription End Date"
                              name="subscriptionEnd"
                              type="date"
                              className={classes.subscriptionEnd}
                            />
                          </GenericGroup>
                          <GenericGroup label="Settings">
                            <div className={classes.twoColumnGroup}>
                              <FormikField
                                component={Checkbox}
                                disabled={isLoading || isSubmitting}
                                label="Two-Factor Authentication"
                                name="tfaRequired"
                                type="checkbox"
                              />
                              {!data?.partnerModal && (
                                <FormikField
                                  component={Checkbox}
                                  disabled={isLoading || isSubmitting}
                                  label="Customer has ELA"
                                  name="ela"
                                  type="checkbox"
                                />
                              )}
                              {/* need to hide below 2-fields for 5.29.1 release(CNS5-10970) and later on in 5.30.LA release will need to revert it */}
                              <div style={{ display: 'none' }}>
                                <FormikField
                                  component={Checkbox}
                                  disabled={isLoading || isSubmitting}
                                  label="Enable Server Side Threats"
                                  name="nonMtdDeviceSupport"
                                  type="checkbox"
                                />
                                <FormikField
                                  component={Checkbox}
                                  disabled={isLoading || isSubmitting}
                                  label="Enable Custom URL Feed"
                                  name="customerSpecificUrlFeed"
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          </GenericGroup>
                        </GenericCard>
                        <GenericCard
                          isLoading={isLoading}
                          loadingHeight={139}
                          title="language preference"
                        >
                          {!isLoading && (
                            <>
                              <FormikField
                                component={Select}
                                disabled={isLoading || isSubmitting}
                                label="Select the default language preference for user accounts"
                                multiple={false}
                                name="languagePreference"
                                options={[
                                  {
                                    value: 'en-us',
                                    label: 'English',
                                  },
                                  {
                                    value: 'ja-jp',
                                    label: 'Japanese',
                                  },
                                ]}
                                setFieldValue={setFieldValue}
                                type="select"
                                placeholder="Choice Required"
                                className={classes.required}
                              />
                            </>
                          )}
                        </GenericCard>
                        {!data?.partnerModal && (
                          <GenericCard
                            isLoading={isLoading}
                            loadingHeight={139}
                            title="required"
                          >
                            {!isLoading && (
                              <>
                                <FormikField
                                  component={Select}
                                  disabled={isLoading || isSubmitting}
                                  label="Allow anonymized data to be uploaded to zLabs"
                                  multiple={false}
                                  name="participateInZlabsResearch"
                                  options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                  ]}
                                  setFieldValue={setFieldValue}
                                  type="select"
                                  placeholder="Choice Required"
                                  className={classes.required}
                                />
                                <FormikField
                                  component={Select}
                                  disabled={isLoading || isSubmitting}
                                  label="Subscribe to Threat Analysis Services"
                                  multiple={false}
                                  name="tasEnabled"
                                  options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                  ]}
                                  setFieldValue={setFieldValue}
                                  type="select"
                                  placeholder="Choice Required"
                                  className={classes.required}
                                />
                                {(values?.tasEnabled?.value === true ||
                                  values?.participateInZlabsResearch?.value ===
                                    true) && (
                                  <>
                                    <FormikField
                                      component={Select}
                                      disabled={isLoading || isSubmitting}
                                      label="Geography"
                                      multiple={false}
                                      name="geography"
                                      options={GEOGRAPHY_OPTIONS}
                                      type="select"
                                      setFieldValue={setFieldValue}
                                      placeholder="Choice Required"
                                      className={classes.required}
                                    />
                                    <FormikField
                                      component={Select}
                                      disabled={isLoading || isSubmitting}
                                      label="Market Vertical"
                                      multiple={false}
                                      name="marketVertical"
                                      options={MARKET_VERTICAL_OPTIONS}
                                      type="select"
                                      setFieldValue={setFieldValue}
                                      placeholder="Choice Required"
                                      className={classes.required}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </GenericCard>
                        )}
                        {!data?.partnerModal && (
                          <FormikField
                            disabled={isLoading || isSubmitting}
                            component={TextField}
                            label="Hostname - Used for SSO"
                            name="hostname"
                          />
                        )}
                      </>
                    ),
                  },
                  {
                    title: 'Data Retention',
                    disabled: isLoading,
                    content: (
                      <>
                        <FormikField
                          component={Select}
                          disabled={isSubmitting}
                          label="Threats"
                          multiple={false}
                          name="threatsDays"
                          options={DATA_RETENTION_VALUES}
                          setFieldValue={setFieldValue}
                          type="select"
                        />
                        <FormikField
                          component={Select}
                          disabled={isSubmitting}
                          label="Audit Logs"
                          multiple={false}
                          name="auditLogDays"
                          options={DATA_RETENTION_VALUES}
                          setFieldValue={setFieldValue}
                          type="select"
                        />
                      </>
                    ),
                  },
                  {
                    title: 'Features',
                    disabled: isLoading,
                    content: (
                      <>
                        {values?.plans.length && values?.featureList ? (
                          <GenericCard
                            isLoading={isLoading}
                            loadingHeight={139}
                            title=""
                          >
                            <GenericGroup label="">
                              <div className={classes.twoColumnGroup}>
                                {Object.keys(values?.featureList).map(
                                  (featureKey, index) => {
                                    return (
                                      <FormikField
                                        key={index}
                                        component={Checkbox}
                                        disabled={isLoading || isSubmitting}
                                        label={
                                          PlanFeaturesType[
                                            featureKey as unknown as number
                                          ]
                                        }
                                        name={`featureList[${featureKey}]`}
                                        type="checkbox"
                                      />
                                    );
                                  }
                                )}
                              </div>
                            </GenericGroup>
                          </GenericCard>
                        ) : (
                          <GenericError errorMessage="You must have at least one plan selected." />
                        )}
                      </>
                    ),
                  },
                ]}
              />
              {!!externalError && <InputError override={externalError} />}
              <div className={classes.buttonPanel}>
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  text="Cancel"
                  onClick={() => toggleModalDirect('AccountsCreateEdit', false)}
                />
                <Button
                  color="secondary"
                  text={`Save ${!!data?.partnerModal ? 'Managed' : ''} Account`}
                  disabled={isLoading || isSubmitting}
                  isLoading={isSubmitting}
                  onClick={() => handleFormikSubmit()}
                />
              </div>
            </DialogContent>
          </FormikForm>
        </div>
      )}
    </Formik>
  );
};

export default Form;
