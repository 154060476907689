import { Android as MUIAndroidIcon } from '@material-ui/icons';
import cc from 'classcat';
import DoughnutChart from 'components/UI/Chart/Doughnut';
import { IHorizontalLegendItem } from 'components/UI/Chart/HorizontalLegend/models';
import GenericCard from 'components/UI/GenericCard';
import AppleIcon from 'components/UI/icons/AppleIcon';
import CircleOutlineIcon from 'components/UI/icons/CircleOutlineIcon';
import { useMemo } from 'react';
import { IDeviceStatus } from '../DashboardTab/models';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';
import { RangeTypes } from 'utils/RangeUtils';

const DURATION = RangeTypes.find(r => r.name === 'GLOBAL.LAST_90_DAYS')?.ordinal;

interface IOSStatusCardProps {
  title: string;
  data?: IDeviceStatus;
}

const OSStatusCard: React.FC<IOSStatusCardProps> = ({ title, data }) => {
  const classes = useStyles();
  const os = title.split(' ')[0];
  const { t, ready } = useTranslation();

  const deviceVulnerable: IHorizontalLegendItem[] = useMemo(
    () => [
      {
        color: '#ff9f1e',
        label: t('GLOBAL.UPGRADEABLE'),
        link: `/console/mtd/devices/?os.name=${os}&upgradeable=true&vulnerable=true&duration=${DURATION}`,
        value: data?.vulnerableUpgradeable ?? 0,
      },
      {
        color: '#f50057',
        label: t('GLOBAL.NON_UPGRADEABLE'),
        link: `/console/mtd/devices/?os.name=${os}&upgradeable=false&vulnerable=true&duration=${DURATION}`,
        value: data?.vulnerableNonUpgradeable ?? 0,
      },
    ],
    [data, os, t]
  );

  const deviceOutOfComp: IHorizontalLegendItem[] = useMemo(
    () => [
      {
        color: '#ff9f1e',
        label: t('GLOBAL.UPGRADEABLE'),
        link: `/console/mtd/devices/?os.name=${os}&upgradeable=true&os.policyCompliant=false&duration=${DURATION}`,
        value: data?.outOfComplianceUpgradeable ?? 0,
      },
      {
        color: '#f50057',
        label: t('GLOBAL.NON_UPGRADEABLE'),
        link: `/console/mtd/devices/?os.name=${os}&upgradeable=false&os.policyCompliant=false&duration=${DURATION}`,
        value: data?.outOfComplianceNonUpgradeable ?? 0,
      },
    ],
    [data, os, t]
  );

  const BigAppleIcon = () => {
    return (
      <div className={classes.iosIcon}>
        <AppleIcon classList={classes} />
      </div>
    );
  };

  if (!ready) {
    return null;
  }

  return (
    <GenericCard title={title} classTitle={classes.title} noPadding>
      <div className={classes.contentContainer}>
        <div
          className={cc([
            classes.itemContents,
            classes.itemContainer,
            classes.totalContainer,
          ])}
        >
          <CircleOutlineIcon
            Icon={os === 'iOS' ? BigAppleIcon : MUIAndroidIcon}
            borderColor={os === 'iOS' ? 'blue' : 'green'}
          />
          <span className={classes.value}>{data?.totalDevices || 0}</span>
          <span className={classes.label}>
            {os !== 'iOS'
              ? t('MTD.OS_RISK.TOTAL_ANDROID_DEVICES')
              : t('MTD.OS_RISK.IOS_VULNERABLE_OS')}
          </span>
          {/* <span className={classes.label}>{`Total ${os} Devices`}</span> */}
        </div>
        <div className={cc([classes.itemContents, classes.itemContainer])}>
          <h2 className={classes.chartTitle}>
            {os !== 'iOS'
              ? t('MTD.OS_RISK.ANDROID_VULNERABLE_OS')
              : t('MTD.OS_RISK.IOS_VULNERABLE_OS')}
          </h2>
          <DoughnutChart
            customSizeClass={classes.chartSize}
            forceProportionate
            forceSegmentsOrder
            icon={
              <div className={classes.iosIcon}>
                <CircleOutlineIcon
                  Icon={os === 'iOS' ? BigAppleIcon : MUIAndroidIcon}
                  borderColor={os === 'iOS' ? 'blue' : 'green'}
                />
              </div>
            }
            segments={deviceVulnerable}
            showChart={(data?.totalVulnerable || 0) > 0}
            summarySuffix={true}
            svgWidthPercentage={70}
            withLegend
          />
        </div>
        <div className={cc([classes.itemContents, classes.itemContainer])}>
          <h2 className={classes.chartTitle}>
            {os !== 'iOS'
              ? t('MTD.OS_RISK.ANDROID_OUT_OF_COMPLIANCE_OS')
              : t('MTD.OS_RISK.IOS_OUT_OF_COMPLIANCE_OS')}
          </h2>
          <DoughnutChart
            customSizeClass={classes.chartSize}
            forceProportionate
            forceSegmentsOrder
            icon={
              <div className={classes.iosIcon}>
                <CircleOutlineIcon
                  Icon={os === 'iOS' ? BigAppleIcon : MUIAndroidIcon}
                  borderColor={os === 'iOS' ? 'blue' : 'green'}
                />
              </div>
            }
            segments={deviceOutOfComp}
            showChart={(data?.totalOutOfCompliance || 0) > 0}
            summarySuffix={true}
            svgWidthPercentage={70}
            withLegend
          />
        </div>
      </div>
    </GenericCard>
  );
};

export default OSStatusCard;
