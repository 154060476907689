import { ISelectItem } from 'components/UI/input/Select';
import { IFormSubmit } from './models';
import {
  PROTECTION_COVERAGE_FIELDS_IOS,
  PROTECTION_COVERAGE_FIELDS,
} from 'components/main/zShieldApps/ProtectionCoverage/utils';

export const initialValues = ({
  data,
  availableTeams,
  availableGroups,
}: {
  data: Partial<IFormSubmit>;
  availableTeams: ISelectItem[];
  availableGroups: ISelectItem[];
}): IFormSubmit => {
  // at this time we do not want to preselect runtime coverage
  const preSelectedProtectionCF = [
    ...PROTECTION_COVERAGE_FIELDS_IOS,
    ...PROTECTION_COVERAGE_FIELDS,
  ]
    .filter((pcf) => pcf.fieldName !== 'runtimeProtection')
    .map(({ fieldName }) => fieldName);
  const editModeSelectedProtectionCF = [
    ...PROTECTION_COVERAGE_FIELDS_IOS,
    ...PROTECTION_COVERAGE_FIELDS,
  ]
    .map((pcf) => (pcf.fieldName in data ? pcf.fieldName : false))
    .filter(Boolean);

  const preSelectedTeam = availableTeams[0]
    ? availableTeams.find(({ value }) => value === data?.teamId)
    : undefined;

  const preSelectedGroup = availableGroups[0]
    ? availableGroups.find(({ value }) => value === data?.groupId)
    : undefined;

  return {
    appHardening: data?.appHardening ?? false,
    appIntegrity: data?.appIntegrity ?? true,
    description: data?.description ?? '',
    file: data?.file ?? null,
    team: preSelectedTeam,
    group: preSelectedGroup,
    platform: data?.platform ?? 'ANDROID',
    version: data?.version ?? '',
    buildNumber: data?.buildNumber ?? undefined,
    protectionCoverages: data?.groupId
      ? (editModeSelectedProtectionCF as string[])
      : preSelectedProtectionCF,
    name: data?.name,
    appId: data?.appId,
    buildId: data?.buildId,
    bundleId: data?.bundleId,
  };
};
