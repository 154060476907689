import React, { Fragment } from 'react';

// components
import ZButton from 'UI/Buttons/ZButton';

// ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  toggleModalDiffered,
  toggleModalDirect,
  openSnackBar,
} from 'utils/storeUtils';

// actions
import { publishEvent } from 'utils/eventUtils';
import { deleteUserActivation } from 'api/UserActivationsService';

function UserActivationsDeleteConfirm(props) {
  function handleSubmit() {
    deleteUserActivation({ id: props.data.rowData.id })
      .then(() => {
        toggleModalDirect('UserActivationsDeleteConfirm', false);
        openSnackBar('End user activation was successfully deleted');
        publishEvent('table:force-fetch-deviceActivations');
      })
      .catch((error) => {
        toggleModalDirect('UserActivationsDeleteConfirm', false);
        openSnackBar('Unable to delete end user activation');
        console.log(error);
      });
  }
  return (
    <Fragment>
      <DialogContent>
        <p>Are you sure you want to delete {props.data.rowData.email}</p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            buttonText="Cancel"
            color="secondary"
            action={toggleModalDiffered('UserActivationsDeleteConfirm', false)}
          />
          <ZButton
            buttonType="submit"
            styleName="modalDelete"
            buttonText="Delete"
            color="primary"
            action={handleSubmit}
          />
        </DialogActions>
      </DialogContent>
    </Fragment>
  );
}

export default UserActivationsDeleteConfirm;
