import axios from 'axios';
import withRouter from 'components/hocs/withRouter';
import React, { useState } from 'react';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import Form from './Form';
import { IFormSubmit } from './Form/models';
import { formatErrorMessage } from './Form/utils';
import { FormikHelpers } from 'formik';
import { Api } from 'config/axiosConfig';
interface IZShieldUploadAppProps {
  data: Partial<IFormSubmit>;
}

const ZShieldUploadAppForm: React.FC<IZShieldUploadAppProps> = ({ data }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [fileData, setFileData] = useState<File | null>(null);
  const editMode = !!data && Object.keys(data).length !== 0;

  const resetModal = () => {
    setErrorMessage('');
  };

  const handleSubmit = async (
    values: IFormSubmit,
    formikHelpers: FormikHelpers<IFormSubmit>
  ) => {
    const apiUrl = `api/zapp/v1/builds/${values?.buildId}/protect`;
    try {
      // Create a FormData object to handle multipart data
      const formData = new FormData();

      // Append form fields to FormData
      formData.append('description', values?.description || '');
      formData.append('groupId', (values?.group?.value as string) || '');
      formData.append('teamId', (values?.team?.value as string) || '');

      // Append protectionCoverages dynamically to FormData

      if (values?.protectionCoverages) {
        const uniqueObjects = Array.from(
          new Set(
            values?.protectionCoverages.map((item) => JSON.stringify(item))
          )
        ).map((item) => JSON.parse(item));
        uniqueObjects.forEach((protectionCoverage) => {
          formData.append(`${protectionCoverage}`, 'true');
        });
      }
      // Append file if present
      if (values?.file || fileData) {
        formData.append('file', fileData as File);
      }

      // Make API call using FormData
      await Api.put(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Reload tables and close modal after success
      publishEvent('zshield:reload-tables');
      toggleModalDirect('ZShieldRegisterApp', false);
      openSnackBar(
        'Your application is currently being processed, please check the status of your app by refreshing the page after some time. You will also receive email(s) regarding updates'
      );
    } catch (e) {
      console.error('Error in updating build:', e);
      formikHelpers.setSubmitting(false);
      if (axios.isAxiosError(e)) {
        const status = e?.response?.status ?? -1;
        let statusText = e?.response?.statusText ?? 'Unknown Error';
        if (status === 403) {
          statusText = 'Unauthorized Error';
        }
        if (status === 404) {
          statusText = 'Build is not found';
        }
        const dataString =
          typeof e?.response?.data === 'string'
            ? e.response.data
            : 'Please Try Again';
        setErrorMessage(formatErrorMessage(statusText, dataString, status));
      } else {
        setErrorMessage(formatErrorMessage('Upload Error', 'Please Try Again'));
      }
    }
  };

  return (
    <Form
      data={data}
      editMode={editMode}
      errorMessage={errorMessage}
      handleSubmit={handleSubmit}
      resetModal={resetModal}
      fileData={fileData}
      setFileData={setFileData}
      setErrorMessage={setErrorMessage}
    />
  );
};

export default withRouter(ZShieldUploadAppForm);
