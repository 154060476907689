import { AxiosResponse } from 'axios';
import { Api } from 'config/axiosConfig';
import { IPolicyGroup } from './models';

export const fetchPolicyGroups = async (teamId?: string) => {
  try {
    const apiUrl = `api/groups/v1/groups?teamid=${teamId}`;
    const response: AxiosResponse<IPolicyGroup[]> = await Api.get(apiUrl);
    return response.data.map(({ name, id }) => ({
      label: name,
      value: id,
      // teamId: team?.id ?? '',
    }));
  } catch (e) {
    console.error(e);
  }
};

export const formatErrorMessage = (
  statusText: string,
  dataString: string,
  status?: number
) => {
  if (status === 404 || status === 400) {
    return `${dataString ?? 'Not Found - Error reaching server.'}`;
  }
  if (status === 401) {
    return 'Unauthorized - Session Timeout';
  }
  if (status === 503) {
    return 'A Bad Gateway (503) error was generated during upload';
  }
  if (status === 504) {
    return 'A request timeout was generated during upload.';
  }

  const errorStatus = statusText ?? 'Upload Error';
  const responseData = dataString ?? 'Please Try Again';

  if (errorStatus === 'Conflict') {
    return 'File already exists';
  }
  return `${errorStatus} - ${responseData}`;
};
