import { Card as MUICard } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { default as MUIDeleteIcon } from '@material-ui/icons/Delete';
import cc from 'classcat';
import { default as OldUploadComplete } from 'components/fileuploader/UploadComplete';
import Uploader from 'components/UI/Uploader';
import { default as OldUploadError } from 'components/fileuploader/UploadError';
import Button from 'components/UI/Button';
import GenericCard from 'components/UI/GenericCard';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { toggleModalDiffered } from 'utils/storeUtils';
import useStyles from './useStyles';
import GenericError from 'components/UI/GenericErrorBox';

interface IFormProps {
  errorMessage: string;
  fileData: File | null;
  handleSubmit: () => void;
  resetModal: () => void;
  setFileData: Dispatch<SetStateAction<File | null>>;
  uploaded: boolean;
  uploadedPercent: number;
}
// TODO: Replace later
const UploadError: any = OldUploadError;
const UploadComplete: any = OldUploadComplete;

const Form: React.FC<IFormProps> = ({
  errorMessage,
  fileData,
  handleSubmit,
  resetModal,
  setFileData,
  uploaded,
  uploadedPercent,
}) => {
  const classes = useStyles();

  const handleRemoveFile = useCallback(() => {
    setFileData(null);
  }, [setFileData]);

  const getFileData = useCallback(
    (filedata: File) => {
      setFileData(filedata);
    },
    [setFileData]
  );

  return (
    <>
      <div className={classes.header}>
        <h1 className={classes.headerText}>Upload App</h1>
      </div>
      <DialogContent>
        <p>Upload your Android or iOS application for protection</p>
        <GenericCard
          title={
            <span className={classes.fileUploadTypeText}>
              SELECT APK/BUNDLE FOR ANDROID or XCARCHIVE.ZIP FOR iOS
            </span>
          }
        >
          <GenericError
            errorMessage={
              'dSYM currently must be included when submitting xcarchive for protection'
            }
            customClass={classes.errorInfoBox}
          />
          {!errorMessage && !!fileData?.name ? (
            <div>
              <MUICard classes={{ root: classes.fileInfoCard }}>
                <div className="text__ellipsis">{fileData.name}</div>
                <button
                  className={cc([
                    classes.fileInfoDeleteButton,
                    {
                      [classes.disabled]: false, //isSubmitting,
                    },
                  ])}
                  disabled={false} //{'isSubmitting'}
                  onClick={handleRemoveFile}
                  type="button"
                >
                  <MUIDeleteIcon />
                </button>
              </MUICard>
              {!errorMessage && (uploadedPercent ?? 0) > 1 && (
                <LinearProgress variant="determinate" value={uploadedPercent} />
              )}
            </div>
          ) : (
            <div className={classes.uploaderDropZone}>
              <Uploader
                accept=".apk,.zip,.aab"
                getFileData={getFileData}
                isShowing
              />
            </div>
          )}
        </GenericCard>

        {!errorMessage && (
          <div className={classes.buttonPanel}>
            <Button
              color="secondary"
              disabled={false} //{isSubmitting}
              onClick={toggleModalDiffered('ZShieldUploadApp', false)}
              text="Cancel"
            />
            <Button
              color="secondary"
              disabled={fileData === null || (uploadedPercent ?? 0) > 1}
              onClick={handleSubmit}
              text="Upload App"
            />
          </div>
        )}
        <UploadError
          errorMessage={errorMessage}
          handleCloseModal={toggleModalDiffered('ZShieldUploadApp', false)}
          isShowing={!!errorMessage}
          retryUpload={resetModal}
        />
        <UploadComplete
          errorMessage={errorMessage}
          handleCloseModal={toggleModalDiffered('ZShieldUploadApp', false)}
          isShowing={uploaded && !errorMessage}
        />
      </DialogContent>
    </>
  );
};

export default Form;
