import { ISelectItem } from 'components/UI/input/Select';
import { ELanguageLabel, TLanguageOption } from 'models/language';
import Moment from 'moment';
import { IAccountData, ThreatDefaultsSource } from './models';
import { generateRetentionValue, getGeography, getMarketVerticalOption, resolveAccountType } from './utils';

const initialValues = (data: Partial<IAccountData>) => ({
  accountName: data?.name ?? '',
  accountType: resolveAccountType(data),
  auditLogDays: !!data?.dataRetentionSettings?.auditLogDays
    ? generateRetentionValue(data.dataRetentionSettings.auditLogDays)
    : { label: '90 days', value: 90 },
  description: data?.description ?? '',
  ela: data?.subscription?.ela,
  hostname: data?.hostname ?? '',
  languagePreference: {
    label: !!data?.languagePreference
      ? ELanguageLabel[data.languagePreference.toLowerCase() as keyof typeof ELanguageLabel]
      : 'English',
    value: !!data?.languagePreference ? data.languagePreference : 'en-us',
  } as ISelectItem<TLanguageOption>,
  managingAccount: !!data?.managingAccount
    ? {
      label: data.managingAccount?.name,
      value: data.managingAccount?.id,
    }
    : undefined,
  participateInZlabsResearch:
    'boolean' === typeof data?.participateInZlabsResearch
      ? data.participateInZlabsResearch
        ? { label: 'Yes', value: true }
        : { label: 'No', value: false }
      : undefined,
  plans: (data?.subscription?.plans ?? []).map(({ id }) => id),
  salesforceId: data?.subscription?.salesforceId ?? '',
  subscriptionEnd: !!data?.subscription?.subscriptionEnd
    ? Moment(data.subscription.subscriptionEnd).format('YYYY-MM-DD').toString()
    : Moment().add(60, 'days').format('YYYY-MM-DD').toString(),
  tasEnabled:
    'boolean' === typeof data?.tasEnabled
      ? data.tasEnabled
        ? { label: 'Yes', value: true }
        : { label: 'No', value: false }
      : undefined,
  tfaRequired: data?.tfaRequired,
  threatsDays: !!data?.dataRetentionSettings?.threatsDays
    ? generateRetentionValue(data.dataRetentionSettings.threatsDays)
    : { label: '90 days', value: 90 },
  geography: getGeography(data?.geography ?? ''),
  marketVertical: getMarketVerticalOption(data?.marketVertical ?? ''),
  featureList: data?.featureList,
  threatDefaultsSource: data?.threatDefaultsSource ?? ThreatDefaultsSource.GTS,
  nonMtdDeviceSupport: !!data?.nonMtdDeviceSupport || false,
  customerSpecificUrlFeed: !!data?.customerSpecificUrlFeed || false,

});

export default initialValues;
