import axios from 'axios';
import { Api } from 'config/axiosConfig';
import { FormikHelpers } from 'formik';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import { IFormData } from './Form/models';
import { IPayload } from './models';
import { TFunction } from 'react-i18next';

export const handleSubmit = async (
  formData: IFormData,
  formikHelpers: FormikHelpers<IFormData>,
  t: TFunction<'translation', undefined>
) => {
  const payload: IPayload = {
    activationLimit: (formData?.activationLimit as number) ?? null,
    email: formData?.email ?? null,
    firstName: formData?.firstName ?? null,
    groupId: (formData?.group?.value as string) ?? null,
    lastName: formData?.lastName ?? null,
    phoneNumber: formData?.phoneNumber ?? null,
    sendEmailInvite: formData?.sendEmailInvite ?? false,
  };

  const baseUrl = 'api/zapp/v1/user-activations';

  if (!!formData?.editMode) {
    try {
      const teamId = formData?.team?.value || null;
      const apiUrl = `${baseUrl}/${formData.id}`;
      await Api.put(apiUrl, { ...payload, teamId });
      openSnackBar(
        // t(`Successfully updated end user activation for ${payload.email}`)
        t('MTD.ACTIVATIONS.SUCCESSFULLY_UPDAYED_END_USER', {
          email: payload.email,
        })
      );
      publishEvent('table:force-fetch-deviceActivations');
      toggleModalDirect('UserActivationsCreateEdit', false);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e?.response?.status === 409) {
        formikHelpers.setErrors({
          email: t('MTD.ACTIVATIONS.ACTIVATION_MAIL_ALREADY_EXIST'),
        });
      } else {
        formikHelpers.setErrors({
          email: t('MTD.ACTIVATIONS.FAILED_TO_UPLOAD_END_USER'),
        });
      }
      formikHelpers.setSubmitting(false);
      console.error(e);
    }
  } else {
    const teamId = formData?.selectedTeamId || formData?.team?.value;

    try {
      const apiUrl = baseUrl;
      await Api.post(apiUrl, { ...payload, teamId });
      openSnackBar(
        // `Successfully created end user activation for ${payload.email}`
        t('MTD.ACTIVATIONS.SUCCESSFULLY_CREATED_END_USER', {
          email: payload.email,
        })
      );
      publishEvent('table:force-fetch-deviceActivations');
      toggleModalDirect('GroupsCreateEdit', false);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e?.response?.status === 409) {
        formikHelpers.setErrors({
          email: t('MTD.ACTIVATIONS.ACTIVATION_MAIL_ALREADY_EXIST'),
        });
      } else {
        formikHelpers.setErrors({
          email: t('MTD.ACTIVATIONS.FAILED_TO_CREATE_END_USER'),
        });
      }
      formikHelpers.setSubmitting(false);
      console.error(e);
    }
  }
};
