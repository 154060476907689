import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: '21px',
      height: '100%',
      justifyContent: 'center',
      position: 'relative',
      width: '96%',
      textAlign: 'center',
    },
    errorText: {
      display: 'flex',
      alignItems: 'center',
      color: palette.text.secondary,
    },

    errorOutline: {
      marginRight: 5,
      verticalAlign: 'bottom',
      color: palette.error.main,
    },

    fileButton: {
      backgroundColor: palette.primary.main,
      borderRadius: 3,
      color: palette.text.secondary,
      cursor: 'pointer',
      display: 'flex',
      fontSize: '0.875rem',
      fontWeight: 400,
      justifyContent: 'center',
      lineHeight: 1.75,
      marginBottom: 20,
      minWidth: 64,
      padding: '6px 16px',
    },
    fileUploaderButton: {
      backgroundColor: palette.secondary.main,
      borderRadius: 3,
      color: palette.text.secondary,
      cursor: 'pointer',
      display: 'flex',
      fontSize: '0.875rem',
      fontWeight: 400,
      justifyContent: 'center',
      lineHeight: 1.75,
      minWidth: 64,
      padding: '6px 16px',
    },

    hideFile: {
      cursor: 'pointer',
      fontSize: 24,
      height: '100%',
      opacity: 0,
      position: 'absolute',
      width: '100%',
      zIndex: 1000,
    },
    errorBox: {
      position: 'absolute',
      bottom: '9px',
      width: '100%',
      padding: '0px 10px',
    },
  })
);

export default useStyles;
