import cc from 'classcat';
import AbstractProportionChart from 'components/UI/Chart/AbstractProportion';
import GenericCard from 'components/UI/GenericCard';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import { useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { IInsightsChart } from '../models';
import { default as useSharedStyles } from '../useStyles';
import { EInsightsDashboardOSType, IDeviceOSStatus } from './models';
import useStyles from './useStyles';
import { fetchOSRiskData, generateAbstractProportionChart } from './utils';
import { useTranslation } from 'react-i18next';

const CurrentOsRisk: React.FC<IInsightsChart> = ({
  accountId,
  selectedTeamId,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { t, ready } = useTranslation();


  const deviceStats = useAsyncResult<IDeviceOSStatus>(
    fetchOSRiskData,
    accountId,
    selectedTeamId ?? ''
  );

  const abstractColumnsAndroid = useMemo(
    () =>
      generateAbstractProportionChart(
        t,
        deviceStats?.platformData?.android?.vulnerableNonUpgradeable,
        EInsightsDashboardOSType.ANDROID,
        deviceStats?.platformData?.android?.vulnerableUpgradeable,
      ),
    [deviceStats, t]
  );
  const abstractColumnsIos = useMemo(
    () =>
      generateAbstractProportionChart(
        t,
        deviceStats?.platformData?.ios?.vulnerableNonUpgradeable,
        EInsightsDashboardOSType.IOS,
        deviceStats?.platformData?.ios?.vulnerableUpgradeable,
      ),
    [deviceStats, t]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard className={cc([sharedClasses.card, classes.osRiskCard])}>
      <h2>{t('MTD.INSIGHTS.CURRENT_OS_RISK')}</h2>
      <div className={sharedClasses.column}>
        <div className={cc([classes.osRiskRow, sharedClasses.row])}>
          <div className={cc([classes.osRiskIcon, classes.osRiskIconAndroid])}>
            <AndroidIcon />
          </div>
          <AbstractProportionChart
            columns={abstractColumnsAndroid}
            className={cc([classes.osRiskChart, classes.osRiskChartAndroid])}
          />
        </div>
        <div className={cc([classes.osRiskRow, sharedClasses.row])}>
          <div className={cc([classes.osRiskIcon, classes.osRiskIconIos])}>
            <AppleIcon classList={classes} />
          </div>
          <AbstractProportionChart
            columns={abstractColumnsIos}
            className={cc([classes.osRiskChart, classes.osRiskChartIos])}
          />
        </div>
      </div>
    </GenericCard>
  );
};

export default CurrentOsRisk;
