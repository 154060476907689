import React, { Fragment } from 'react';
import CheckBox from 'components/inputs/Checkbox';
import CropFree from '@material-ui/icons/CropFree';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import { toggleModalDiffered } from 'utils/storeUtils';
import momentHelper from 'utils/momentUtils';
import ProtectedComponent from '../protected/ProtectedComponent';

export const DeviceActivationsTableHeaders = [
  // checkbox
  { id: 'email', label: 'GLOBAL.EMAIL', show: true, sort: true },
  {
    id: 'firstName',
    label: 'USER_SETTINGS.PERSONAL_INFO.FIRST_NAME',
    show: true,
    sort: true,
  },
  {
    id: 'lastName',
    label: 'USER_SETTINGS.PERSONAL_INFO.LAST_NAME',
    show: true,
    sort: true,
  },
  { id: 'phoneNumber', label: 'GLOBAL.PHONE_NUMBER', show: true, sort: true },
  {
    id: 'activationCount',
    label: 'MTD.ACTIVATIONS.ACTIVATION_COUNT',
    show: true,
    sort: true,
  },
  {
    id: 'activationLimit',
    label: 'MTD.ACTIVATIONS.ACTIVATION_LIMIT',
    show: true,
    sort: true,
  },
  { id: 'team.name', label: 'GLOBAL.TEAM', show: true, sort: false },
  { id: 'groupName', label: 'GLOBAL.POLICY_GROUP', show: true, sort: false },
  { id: 'created', label: 'GLOBAL.CREATED', show: true, sort: true },
  { id: 'modified', label: 'GLOBAL.MODIFIED', show: false, sort: true },
  {
    id: 'externalSubscriptionId',
    label: 'MTD.ACTIVATIONS.EXTERNAL_SUBSCRIPTION_ID',
    show: false,
    sort: true,
  },
  { id: 'id', label: 'GLOBAL.ACTIONS', show: true },
];

export const DeviceActivationsColumnMapping = (t) => [
  {
    path: 'id',
    columnContent: (id, { handlers, rowData }) => {
      return (
        <CheckBox
          key={rowData.id}
          id="activations--checkbox"
          checked={
            !!(handlers.checkedBoxes && handlers.checkedBoxes[rowData.id])
          }
          onChange={handlers.handleCheckbox({ ...rowData })}
        />
      );
    },
  },
  {
    path: 'email',
    columnContent: (email) => (
      <span className="table-cell__ellipsis">{email}</span>
    ),
  },
  { path: 'firstName' },
  { path: 'lastName' },
  { path: 'phoneNumber' },
  { path: 'activationCount' },
  { path: 'activationLimit' },
  { path: 'team.name' },
  {
    path: 'groupName',
    columnContent: (groupName) => {
      return (
        <Tooltip title={groupName}>
          <span
            style={{
              width: 150,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
            }}
          >
            {groupName}
          </span>
        </Tooltip>
      );
    },
  },
  {
    path: 'created',
    columnContent: (created) => {
      return momentHelper(created);
    },
  },
  {
    path: 'modified',
    columnContent: (modified) => {
      return momentHelper(modified);
    },
  },
  {
    path: 'externalSubscriptionId',
  },
  {
    path: 'actions',
    columnContent: (
      id,
      { rowData, handlers: { availableTeams, selectedTeamId } }
    ) => {
      return (
        <Fragment>
          <Tooltip title={t('MTD.ACTIVATIONS.EDIT_ACTIVATION_LINK')}>
            <CropFree
              className="hover--link"
              onClick={toggleModalDiffered(
                'ActivationsLink',
                {
                  rowData,
                },
                {
                  title: t('MTD.ACTIVATIONS.MANAGE_ACTIVATION_LINK'),
                }
              )}
            />
          </Tooltip>
          <ProtectedComponent allow={{ zapps: 'manage' }}>
            <Tooltip title={t('MTD.ACTIVATIONS.EDIT_END_USER')}>
              <EditIcon
                className="hover--link"
                onClick={toggleModalDiffered(
                  'UserActivationsCreateEdit',
                  {
                    availableTeams,
                    rowData,
                    selectedTeamId,
                  },
                  {
                    title: t('MTD.ACTIVATIONS.EDIT_END_USER_ACTIVATION'),
                    fullWidth: true,
                  }
                )}
              />
            </Tooltip>
          </ProtectedComponent>
        </Fragment>
      );
    },
  },
  { path: '' },
];

export const fetchUserActivationsParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
  {
    path: 'groupId',
  },
  {
    path: 'teamId',
    paramKey: 'teamIds',
  },
];

export const processCsvTableData = (values, tableData) => {
  return tableData.map((row) => {
    return {
      email: row[0].trim(),
      firstName: row[1].trim(),
      lastName: row[2].trim(),
      phoneNumber: row[3].trim(),
      groupId: values.group.value,
      activationLimit: values.activationLimit,
      sendEmailInvite: values.sendEmailInvite,
      // sms not yet supported
      sendSmsInvite: values.sendSmsInvite,
      teamId: values.team.value,
    };
  });
};
