import cc from 'classcat';
import DrawerHeader from 'components/UI/DrawerHeader';
import Tabs from 'components/UI/Tabs';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAsyncResult from 'utils/useAsyncResult';
import DetailTable from '../../common/DetailTable';
import { IPolicyMetaData } from '../../common/DetailTable/models';
import { IThreatDrawerData } from './models';
import useStyles from './useStyles';
import { fetchPolicyMetadata, getAllDetails, getTabs } from './utils';

const Drawer: React.FC = () => {
  const classes = useStyles();

  const { t, ready } = useTranslation();

  const { closeDrawer, drawerData } =
    useContext<Partial<IDrawerContext<IThreatDrawerData>>>(DrawerContext);

  const policyMetadata: IPolicyMetaData[] = useAsyncResult(
    fetchPolicyMetadata,
    drawerData?.rowData?.groupId ?? ''
  );

  const details = useMemo(
    () =>
      getAllDetails(
        classes,
        t,
        drawerData?.rowData,
        policyMetadata,
        drawerData?.module
      ),
    [classes, drawerData, policyMetadata, t]
  );

  const tabs = useMemo(
    () => getTabs(classes, t, drawerData?.rowData),
    [classes, drawerData?.rowData, t]
  );

  const handleClose = useCallback(() => {
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
  }, [closeDrawer]);

  useEffect(() => {
    window.addEventListener(`auth:logout`, handleClose);

    return () => {
      window.removeEventListener(`auth:logout`, handleClose);
    };
  }, [handleClose]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <DrawerHeader
        onClose={handleClose}
        os={drawerData?.rowData?.device?.os?.name ?? ''}
        title={
          drawerData?.rowData?.device?.model ?? t('MTD.THREATS.THREAT_DETAILS')
        }
      />
      <div className={cc([classes.drawerCard, classes.topCardMargin])}>
        <div className={classes.topSection}>
          <DetailTable
            details={details.topSectionDeviceDetails}
            onFilterClick={closeDrawer}
          />
          <DetailTable
            details={details.topSectionFocusAppDetails}
            onFilterClick={closeDrawer}
          />
          <DetailTable
            details={details.topSectionCombinedDetails}
            onFilterClick={closeDrawer}
          />
        </div>
      </div>
      <div className={cc([classes.drawerCard, classes.eventCard])}>
        <div>
          <div className={classes.eventHeader}>
            <h3>
              {t('MTD.THREATS.INCIDENT_SUMMARY')}:{' '}
              {drawerData?.rowData?.serverName ?? ''}
            </h3>
            <h3>
              {t('MTD.THREATS.EVENT_ID')}: {drawerData?.rowData?.zeventId ?? ''}
            </h3>
          </div>
          <div className={classes.eventContent}>
            <div className={classes.eventDescription}>
              {drawerData?.rowData?.description ?? ''}
            </div>
            <DetailTable
              details={details?.eventDetails}
              onFilterClick={closeDrawer}
            />
          </div>
        </div>
      </div>
      <div className={classes.tabs}>
        <Tabs tabs={tabs} />
      </div>
    </>
  );
};

export default Drawer;
