import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    buttonPanel: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '20px 0',
    },
    checkboxLabel: {
      color: palette.text.primary,
      fontSize: 14,
    },
    disabled: {
      cursor: 'auto',
      opacity: '30%',
    },
    fileButton: {
      backgroundColor: palette.primary.main,
      borderRadius: 3,
      boxSizing: 'border-box',
      color: palette.text.secondary,
      cursor: 'pointer',
      fontSize: '0.875rem',
      fontWeight: 400,
      left: '50%',
      lineHeight: 1.75,
      marginBottom: 10,
      minWidth: 64,
      padding: '6px 16px',
      position: 'absolute',
      top: '67%',
      transform: 'translate(-50%, -50%)',
    },
    fileInfoCard: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: '20px',
      padding: '10px',
      margin: '20px',
      justifyContent: 'space-between',
      outline: `2px dashed ${palette.primary.main}`,
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      WebkitTransition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
    },
    fileInfoDeleteButton: {
      background: 'none',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      font: 'inherit',
      margin: '0px',
      padding: '0px',
    },
    header: {
      backgroundColor: palette.common.darkGreySoft,
      borderRadius: '3px',
      padding: '18px 0px 16px 0px',
      textAlign: 'center',
      width: '100%',
    },
    headerText: {
      color: palette.primary.contrastText,
      fontWeight: 400,
      margin: '0px',
      padding: '0px',
      paddingLeft: '22px',
      textTransform: 'uppercase',
      width: '100%',
    },
    protectionCoverageBox: {
      paddingRight: 25,
    },
    protectionCoverageHeader: {
      display: 'flex',
      fontSize: 12,
      justifyContent: 'flex-start',
      paddingTop: 10,
    },
    twoColumnGroup: {
      alignItems: 'flex-end',
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: '5px',
      gridTemplateColumns: '50% 50%',
      gridTemplateRows: 'auto auto',
      padding: '0 10px',
    },
    uploaderDropZone: {
      WebkitTransition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: 250,
      outline: `2px dashed ${palette.primary.main}`,
      outlineOffset: '-10px',
      paddingTop: 10,
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
    },
    fileUploadTypeText: {
      fontSize: 16,
    },
    errorInfoBox: {
      alignItems: 'center',
      background: palette.background.default,
      borderColor: palette.charts.orange,
      borderStyle: 'double',
      borderWidth: 1.5,
      display: 'flex',
      justifyContent: 'center',
      padding: '2px 10px',
      cursor: 'default',
      margin: '10px 0',
      fontSize: '14px',
    },
    subHeaderText: {
      textAlign: 'center',
    },
  })
);

export default useStyles;
